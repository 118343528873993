import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {MobileMenuComponent} from '@odp/shared';
import {AboutComponent} from './features/about/about.component';
import {AssayComponent} from './features/assay/assay/assay.component';
import {AssayOverviewComponent} from './features/assay/overview/overview.component';
import {HighlightsComponent} from './features/highlights/highlights.component';
import {HomepageComponent} from './features/homepage/homepage.component';
import {MonkeypoxComponent} from './features/monkeypox/monkeypox.component';
import {learningRoutes} from './routes/learning.routes';

const routes: Routes = [
  {
    path: 'mpox',
    component: MonkeypoxComponent
  },
  {
    path: 'rwd',
    children: learningRoutes
  },
  {
    path: 'covid19',
    children: [
      {
        path: '',
        component: MobileMenuComponent,
        children: [
          {
            path: '',
            component: HomepageComponent
          }
        ]
      },
      {
        path: 'highlights',
        component: MobileMenuComponent,
        children: [
          {
            path: '',
            component: HighlightsComponent
          }
        ]
      },
      {
        path: 'about',
        component: MobileMenuComponent,
        children: [
          {
            path: '',
            component: AboutComponent
          }
        ]
      },
      {
        path: 'curveclasses',
        loadChildren: () =>
          import('./lazy-routes/screening-data/curve-classes-route.module').then((m) => m.CurveClassesRouteModule)
      },
      {
        path: 'databrowser',
        loadChildren: () =>
          import('./lazy-routes/screening-data/data-browser-route.module').then((m) => m.DataBrowserRouteModule)
      },
      {
        path: 'GS-441524',
        loadChildren: () =>
          import('./lazy-routes/screening-data/gs4415245-route.module').then((m) => m.GS441524RouteModule)
      },
      {
        path: 'assays',
        loadChildren: () =>
          import('./lazy-routes/assay-details/assay-details-route.module').then((m) => m.AssayDetailsGridComponent)
      },
      {
        path: 'animal-models',
        loadChildren: () =>
          import('./lazy-routes/animal-models/animal-models-route.module').then((m) => m.AnimalModelsModule)
      },
      {
        path: 'therapeutic-glossary',
        loadChildren: () =>
          import('./lazy-routes/therapeutic-glossary/therapeutic-glossary-route.module').then(
            (m) => m.ThrapeuticGlosseryRouteModule
          )
      },
      {
        path: 'variant',
        children: [
          {
            path: 'activity',
            loadChildren: () =>
              import('./lazy-routes/activity/activity-route.module').then((m) => m.ActivityRouteModule)
          },
          {
            path: 'pandemic-history',
            loadChildren: () =>
              import('./lazy-routes/circulating-variants/circulating-variants-route.module').then(
                (m) => m.CirculatingVariantsRouteModule
              )
          },
          {
            path: 'summary',
            loadChildren: () => import('./lazy-routes/summary/summary-route.module').then((m) => m.SummaryRouteModule)
          },
          {
            path: 'trace',
            loadChildren: () => import('./lazy-routes/trace/trace-route.module').then((m) => m.TraceRouteModule)
          },
          {
            path: 'datasets',
            loadChildren: () =>
              import('./lazy-routes/datasets/datasets-route.module').then((m) => m.DatasetsRouteModule)
          },
          {
            path: 'heterologous-booster',
            loadChildren: () =>
              import('./lazy-routes/heterologous-booster/heterologous-booster-route.module').then(
                (m) => m.HeterologousBoosterGridComponent
              )
          },
          {
            path: 'multivalent-booster',
            loadChildren: () =>
              import('./lazy-routes/bivalent-booster/bivalent-booster-route.module').then(
                (m) => m.BivalentBoosterGridComponent
              )
          },
          {
            path: 'glossary',
            loadChildren: () =>
              import('./lazy-routes/glossary/glossary-route.module').then((m) => m.GlossaryRouteModule)
          },
          {
            path: 'assays',
            component: MobileMenuComponent,
            children: [
              {
                path: '',
                component: AssayComponent
              }
            ]
          },
          {
            path: 'assays/:id/:assay',
            component: MobileMenuComponent,
            children: [
              {
                path: '',
                component: AssayOverviewComponent
              }
            ]
          },
          {
            path: 'in-vivo',
            loadChildren: () => import('./lazy-routes/in-vivo/in-vivo-route.module').then((m) => m.InVivoGridComponent)
          },
          {
            path: 'real-world-evidence',
            loadChildren: () => import('./lazy-routes/rwe/rwe-route.module').then((m) => m.RWEGridComponent)
          },
          {
            path: 'real-world-evidence-forest-plot',
            loadChildren: () =>
              import('./lazy-routes/rwe-forestplot/rwe-forestplot-route.module').then((m) => m.RWEForestplotRouteModule)
          },
          {
            path: 'related-resources',
            loadChildren: () =>
              import('./lazy-routes/related-resources/resource-route.module').then((m) => m.ResourcesRouteModule)
          },
          {
            path: 'about',
            loadChildren: () => import('./lazy-routes/about/about-route.module').then((m) => m.VariantAboutRouteModule)
          }
        ]
      }
    ]
  },
  {
    path: '',
    redirectTo: 'covid19',
    pathMatch: 'full'
  },
  {
    path: 'rwd-training',
    redirectTo: 'rwd',
    pathMatch: 'full'
  },
  {
    path: 'variant',
    redirectTo: 'covid19',
    pathMatch: 'full'
  },
  {
    path: 'variant/about',
    redirectTo: 'covid19/about',
    pathMatch: 'full'
  },
  {
    path: 'variant/assay-details',
    redirectTo: 'covid19/assays',
    pathMatch: 'full'
  },
  {
    path: 'variant/monkeypox',
    redirectTo: 'mpox',
    pathMatch: 'full'
  },
  {
    path: 'variant/trace/variant-data',
    redirectTo: 'covid19/variant/trace/variant-data',
    pathMatch: 'full'
  },
  {
    path: 'variant/trace/assay',
    redirectTo: 'covid19/variant/trace/assay',
    pathMatch: 'full'
  },
  {
    path: 'variant/trace/reagent',
    redirectTo: 'covid19/variant/trace/reagent',
    pathMatch: 'full'
  },
  {
    path: 'variant/trace/mutation',
    redirectTo: 'covid19/variant/trace/mutation',
    pathMatch: 'full'
  },
  {
    path: 'variant/trace/about',
    redirectTo: 'covid19/variant/trace/about',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/summary',
    redirectTo: 'covid19/animal-models/summary',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/sam-field-guide',
    redirectTo: 'covid19/animal-models/sam-field-guide',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/nhp-field-guide',
    redirectTo: 'covid19/animal-models/nhp-field-guide',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/activ-resources',
    redirectTo: 'covid19/animal-models/activ-resources',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/nhp/:id',
    redirectTo: 'covid19/animal-models/nhp/:id',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/nhp-details',
    redirectTo: 'covid19/animal-models/nhp-details',
    pathMatch: 'full'
  },
  {
    path: 'variant/animal-models/small-animals/:id',
    redirectTo: 'covid19/animal-models/small-animals/:id',
    pathMatch: 'full'
  },
  {
    path: 'variant/assays',
    redirectTo: 'covid19/variant/assays',
    pathMatch: 'full'
  },
  {
    path: 'variant/assays/:id/:assay',
    redirectTo: 'covid19/variant/assays/:id/:assay',
    pathMatch: 'full'
  },
  {
    path: 'variant/activity',
    redirectTo: 'covid19/variant/activity',
    pathMatch: 'full'
  },
  {
    path: 'variant/activity/:variant',
    redirectTo: 'covid19/variant/activity/:variant',
    pathMatch: 'full'
  },
  {
    path: 'variant/summary',
    redirectTo: 'covid19/variant/summary',
    pathMatch: 'full'
  },
  {
    path: 'variant/datasets',
    redirectTo: 'covid19/variant/datasets',
    pathMatch: 'full'
  },
  {
    path: 'variant/heterologous-booster',
    redirectTo: 'covid19/variant/heterologous-booster',
    pathMatch: 'full'
  },
  {
    path: 'variant/multivalent-booster',
    redirectTo: 'covid19/variant/multivalent-booster',
    pathMatch: 'full'
  },
  {
    path: 'variant/variant-glossary',
    redirectTo: 'covid19/variant/glossary',
    pathMatch: 'full'
  },
  {
    path: 'variant/in-vivo',
    redirectTo: 'covid19/variant/in-vivo',
    pathMatch: 'full'
  },
  {
    path: 'variant/real-world-evidence',
    redirectTo: 'covid19/variant/real-world-evidence',
    pathMatch: 'full'
  },
  {
    path: 'variant/related-resources',
    redirectTo: 'covid19/variant/related-resources',
    pathMatch: 'full'
  },
  {
    path: 'variant/variant-about',
    redirectTo: 'covid19/variant/about',
    pathMatch: 'full'
  },
  {
    path: 'variant/therapeutic-glossary',
    redirectTo: 'covid19/therapeutic-glossary',
    pathMatch: 'full'
  },
  {
    path: 'variant/curveclasses',
    redirectTo: 'covid19/curveclasses',
    pathMatch: 'full'
  },
  {
    path: 'variant/databrowser',
    redirectTo: 'covid19/databrowser',
    pathMatch: 'full'
  },
  {
    path: 'variant/GS-441524',
    redirectTo: 'covid19/GS-441524',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
