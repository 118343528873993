<app-portal-header-view></app-portal-header-view>
<app-portal-menu></app-portal-menu>

<div class="jumbotron jumbotron-fluid">
  <div class="container content-container">
    <div class="row text-container">
      <div class="breadcrumb-container">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">
              <a routerLink="/covid19">OpenData Portal Home</a>
            </li>
            <li>&nbsp;>&nbsp;</li>
            <li class="breadcrumb-item active" aria-current="page">
              <a routerLink="/covid19">SARS-CoV-2 Portal</a>
            </li>
          </ol>
        </nav>
      </div>
      <div class="" id="jumbotron_introduction">
        <div class="header inline">OpenData Portal</div>
        <div class="brief">
          The NCATS OpenData Portal is an open science initiative designed to rapidly share NCATS&#39;s own drug
          screening data and protocols, regardless of disease, to allow the community to build on this knowledge and
          accelerate cures to patients.<br /><br />
        </div>
      </div>
    </div>
    <div class="icon-container"><img src="/assets/images/home-background-circle.png" alt="Icon" class="icon" /></div>
  </div>
</div>

<div class="container" id="notification_container">
  <div class="row align-items-center">
    <!-- Left Column (Text) -->
    <div class="col-md-7">
      <div class="h4 title">Putting data to work</div>
      <div class="page-text">
        <p>
          Early in the COVID-19 pandemic, NCATS tested more than 10,000 approved drugs and compounds against SARS-CoV-2
          targets, <b>generating nearly half a million points of experimental drug repurposing data.</b> NCATS OpenData
          Portal was originally created to share and visualize this data in real-time to accelerate discovery and guide
          the exploration of new therapeutic hypotheses. It grew to include large curated datasets of public data,
          including variant therapeutic activity, and in vivo and clinical studies.
        </p>
        <p>
          NCATS OpenData Portal is built to quickly pivot and share data for emerging public health needs, and is
          expanding to include data for other diseases.
          <b
            >We hope you&#39;ll continue to browse, explore, and download these datasets, and use them to build new
            models and insights for SARS-CoV-2 and beyond.</b
          >
        </p>
      </div>
    </div>

    <!-- Spacer -->
    <div class="col-md-1"></div>

    <!-- Right Column (Image) -->
    <div class="col-md-4 d-flex justify-content-center">
      <div class="custom-img-container">
        <img src="/assets/images/home-checked-board.png" class="img-fluid" alt="Responsive image" />
      </div>
    </div>
  </div>
</div>

<app-footer-view></app-footer-view>
